const positions = [
  /*{
    id: "devopsEngineer",
    title: "DevOps Engineer",
    description: "Full-Time - Rome, Italy or Remote",
    details: {
      description: [
        "At Exein we are looking for a DevOps Engineer that is passionate about designing, implementing and scaling large event-driven and microservice based platforms. Our mission is to empower developers with the tools they need to build a more secure IoT landscape.",
        "As a DevOps engineer you will be focusing your time on research, designing and scaling our cloud operations to be able to supports millions of IoT devices; working closely with the Engineering team, you will help enable Engineers to iterate rapidly at scale. If you are a great DevOps engineer with strong understanding of cloud infrastructure and workloads, Infrastructure as Code and CI/CD implementations, we definitely want to hear from you!",
      ],
      responsibilities: [
        "Design and implement cloud platform requirements to support event-driven and microservice based architectures;",
        "Building of robust continuous integration and delivery pipelines with quality gates and metrics;",
        "Automate the setup and teardown of ephemeral environments for engineers;",
        "Optimize cloud security and operations through best practices.",
      ],
      requirements: [
        "Knowledge and experience with IaC tools such as Terraform;",
        "Knowledge and experience with container orchestration (i.e. native K8s, EKS, OpenShift);",
        "Experience with automation tools (e.g. Ansible) with strong focus on reproducible infrastructure;",
        "Experience with AWS along with its services and implementation patterns.",
      ],
      preferred: [
        "Experience on operational reliability and observability – from tracing, to logging to healthchecks and smart deployment strategies;",
        "Ability to define standards in the creation of services, tooling and guidelines for Engineers.",
      ],
    },
  },*/
  {
    id: "backendEngineer",
    title: "Senior Software Engineer",
    description: "Full-Time - Rome, Italy",
    details: {
      description: [
        "At Exein we are looking for a Senior Software Engineer that is keen on designing, building and scaling software platforms and products. Our mission is to empower developers with the tools they need to build a more secure IoT landscape.",
        "As a Senior Software Engineer you will be responsible for designing, prototyping, developing and maintaining Exein's platforms. This includes data streaming pipelines, REST APIs as well as internal services that enable Exein's mission. You will be working closely with the engineering team to shape the user experience that our developer tools and products provide to our customers. If you are a great Software Engineer with design and implementation experience and skills, we definitely want to hear from you!",
      ],
      responsibilities: [
        "Design, develop, update and maintain public API and backend services;",
        "Prototype and deliver new features and applications;",
        "Contribute to the architecture and design (architecture, design patterns, reliability and scaling) of new and current systems.",
      ],
      requirements: [
        "Experience with Rust",
        "Experience with Git and Docker;",
        "Experience with Postgres and Redis;",
        "Experience with Kafka or Redpanda;",
        "Functional programming experience;",
        "Experience with micro-services architecture;",
        "Experience with Restful web services building ergonomic APIs;",
        "Experience in writing modern high-performance and asynchronous applications;",
        "Experience with AWS.",
      ],
      preferred: [
        "Knowledge in TimescaleDB and Postgres storage engine is highly valuable;",
        "Knowledge and experience with GraphQL is a plus;",
        "Experience with firmware, kernel or binary analysis is a plus.",
      ],
    },
  },
  /*{
    id: "juniorFrontend",
    title: "Junior Frontend Developer",
    description: "Full-Time - Rome, Italy or Remote",
    details: {
      description: [
        "At Exein we're looking for a Junior Front End Developer passionate about programming, design and technology. Our mission is to empower developers with the tools they need to build a more secure IoT landscape.",
        "As a Junior Front End Developer, you will be responsible for prototyping, developing and maintaining Exein's user-facing web applications. You will be working closely with the whole tech team to shape the user experience that our developer tools provide to our customers. If you are a great Junior Front End Developer and are passionate about next generation cybersecurity developer tools, we definitely want to hear from you.",
      ],
      responsibilities: [
        "Develop, update and maintain web application functionalities;",
        "Prototype and deliver mockups for new features and applications on a tight schedule;",
        "Participate in planning and work estimation with the UX team and the whole tech team;",
        "Review code quality.",
      ],
      requirements: [
        "Knowledge and experience with HTML, CSS, JavaScript, Typescript;",
        "Knowledge and experience with React;",
        "Knowledge and experience with some state management solutions like Context API, Redux, React query;",
        "Experience with RESTful web services;",
        "Experience with Git;",
        "Excellent communication skills;",
        "Growth mindset, willingness to learn, problem-solving skills, and being a real team player.",
      ],
      preferred: [
        "Familiarity with code optimization and clean code techniques;",
        "Knowledge and experience with Next.js.",
      ],
    },
  },*/
  {
    id: "systemsEngineer",
    title: "Security Research Engineer",
    description: "Full-Time - Rome, Italy",
    details: {
      description: [
        "We are seeking a passionate Security Research Engineer with a strong background in operating system internals — primarily Linux — and cybersecurity. In this important R&D role, you will focus on designing, prototyping, developing, and enhancing Exein's core technologies. Your work will involve deep exploration of system internals to drive innovation in our products.",
        "If you have expertise in low-level programming, a deep understanding of OS internals, and a passion for next-generation cybersecurity, we'd love to hear from you."
      ],
      responsibilities: [
        "Design and implement enhancements to Exein's core security solutions for embedded systems.",
        "Conduct R&D to explore new methodologies and technologies that can be integrated into our products.",
        "Actively participate in open-source projects maintained by Exein and contribute to the broader community.",
        "Contribute to the architecture and design of new and existing systems, focusing on scalability, reliability, and effective design patterns.",
        "Ensure high code quality through diligent review practices."
      ],
      requirements: [
        "Strong knowledge and experience with Linux kernel internals or real-time operating systems.",
        "Proficiency in Rust programming language.",
        "Solid understanding of security-related topics and research.",
        "Experience with Git and collaborative development workflows."
      ],
      preferred: [
        "Experience with extended Berkeley Packet Filter (eBPF) technology.",
        "Familiarity with Yocto Project and Docker.",
        "Experience with other tracing tools and technologies.",
        "Knowledge of C/C++ and Python.",
        "Experience with applied machine learning and anomaly detection.",
        "Familiarity with IDS concepts and implementations."
      ],
    },
  },
];

export default positions;
