import React from "react";
import styles from "./styles.module.css";

const JobDetails = ({
  description,
  requirements,
  responsibilities,
  preferred,
}) => {
  return (
    <div className={styles.container}>
      <span className={styles.sectionName}>Job Details</span>
      <div className={styles.content}>
        {description && (
          <div className={styles.list}>
            <h1>What we're looking for</h1>
            <div className={styles.gridContainer}>
              {description.map((elem, i) => (
                <div key={i}>
                  <p>{elem}</p>
                </div>
              ))}
            </div>
          </div>
        )}
        {responsibilities && (
          <div className={styles.list}>
            <h1>Responsibilities</h1>
            <div className={styles.gridContainer}>
              {responsibilities.map((elem, i) => (
                <div key={i} className={styles.gridRow}>
                  <img
                    src="./icons/arrow_right_blue.svg"
                    width={25}
                    alt="arrow"
                    className={styles.gridRowIcon}
                  />
                  <div className={styles.description}>{elem}</div>
                </div>
              ))}
            </div>
          </div>
        )}
        {requirements && (
          <div className={styles.list}>
            <h1>Requirements</h1>
            <div className={styles.gridContainer}>
              {requirements.map((elem, i) => (
                <div key={i} className={styles.gridRow}>
                  <img
                    src="./icons/arrow_right_blue.svg"
                    width={25}
                    alt="arrow"
                    className={styles.gridRowIcon}
                  />
                  <div className={styles.description}>{elem}</div>
                </div>
              ))}
            </div>
          </div>
        )}
        {preferred && (
          <div className={styles.list}>
            <h1>Preferred</h1>
            <div className={styles.gridContainer}>
              {preferred.map((elem, i) => (
                <div key={i} className={styles.gridRow}>
                  <img
                    src="./icons/arrow_right_blue.svg"
                    width={25}
                    alt="arrow"
                    className={styles.gridRowIcon}
                  />
                  <div className={styles.description}>{elem}</div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default JobDetails;
