import React from "react";
import styles from "./styles.module.css";

const benefits = [
  {
    title: "Competetive Salaries",
    description:
      "Competitive Salaries and possibility to access SOP in an high-growth series A startup.",
  },
  {
    title: "Insurance",
    description:
      "Private health insurance for Italian residents or additional bonus for healthcare for non Italian residents.",
  },
  {
    title: "Unlimited paid time Off",
    description:
      "Unlimited paid time off. To encourage time off from work and ensure overall employee health and wellness, Exein requires each employee to take at least 120 hours of PTO (3 weeks) annually, including at least five consecutive business days.",
  },
  {
    title: "Remote-first culture",
    description:
      "Remote-first culture, but we have really nice offices in Rome and San Francisco whenever you feel like working in an office environment.",
  },
  {
    title: "Equipment",
    description: "Equipment budget: chose your own tools.",
  },
  {
    title: "Family",
    description: "Paid family leave for all employees.",
  },
  {
    title: "Learning",
    description: "Learning & development budget.",
  },
  {
    title: "Have fun",
    description:
      "Company offsites and quarterly fun budgets for non-work activities.",
  },
];

const Benefits = () => (
  <div id="benefits" className={styles.container}>
    <span className={styles.sectionName}>Exein Benefits</span>
    <div className={styles.content}>
      <h1>What you Get</h1>
      <p className={styles.subtitle}>Discover Exein culture and benefits.</p>
      <div className={styles.gridContainer}>
        {benefits.map((elem, i) => (
          <div key={i} className={styles.gridRow}>
            <img
              src="./icons/arrow_right_blue.svg"
              width={25}
              alt="arrow"
              className={styles.gridRowIcon}
            />
            <div className={styles.title}>{elem.title}</div>
            <div className={styles.description}>{elem.description}</div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default Benefits;
