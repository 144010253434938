import Benefits from "../components/HomePage/Benefits";
import InterviewProcess from "../components/HomePage/InterviewProcess";
import Intro from "../components/HomePage/Intro";
import OpenPositions from "../components/HomePage/OpenPositions";

const Home = () => {
  return (
    <>
      <Intro />
      <Benefits />
      <InterviewProcess />
      <OpenPositions />
    </>
  );
};

export default Home;
