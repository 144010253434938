import React from "react";
import styles from "./styles.module.css";

const Intro = ({ title, description }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.positionDetails}>
          <h1>{title}</h1>
          <p className={styles.subtitle}>{description}</p>
        </div>
        <a href={`mailto:jobs@exein.io?subject=${title}`} id="apply">
          <button className={styles.applyNowButton}>
            <span>Apply now</span>
            <img src="./icons/add.svg" width={15} alt="add" />
          </button>
        </a>
      </div>
    </div>
  );
};

export default Intro;
