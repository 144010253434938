import React from "react";
import styles from "./styles.module.css";

const process = [
  {
    title: "Introductory Call",
    description:
      "A short call with the People team to go through everything you need to know about the Company and position.",
  },
  {
    title: "Technical Interview",
    description:
      "A purely technical interview with the relevant team to evaluate your approach to problem solving and your past experience.",
  },
  {
    title: "Final Round",
    description: "A short interview with the head of the relevant department.",
  },
];

const InterviewProcess = () => (
  <div id="interviewProcess" className={styles.container}>
    <span className={styles.sectionName}>Interview Process</span>
    <div className={styles.content}>
      <h1>The Interview Process</h1>
      <div className={styles.gridContainer}>
        {process.map((elem, i) => (
          <div key={i} className={styles.gridRow}>
            <div className={styles.title}>{elem.title}</div>
            <div className={styles.description}>{elem.description}</div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default InterviewProcess;
