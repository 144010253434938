import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AboutUs from "../components/OpenPositionPage/AboutUs";
import Intro from "../components/OpenPositionPage/Intro";
import JobDetails from "../components/OpenPositionPage/JobDetails";
import positionsData from "../data/positions";

const OpenPosition = () => {
  const { pathname } = useLocation();

  const [jobDetails, setJobDetails] = useState({});
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    const filteredJob = positionsData.filter(
      (data) => `/${data.id}` === pathname
    );
    setJobDetails(filteredJob[0]);
    setIsDataLoaded(true);
  }, [pathname]);

  return isDataLoaded ? (
    <>
      <Intro title={jobDetails.title} description={jobDetails.description} />
      <JobDetails
        description={jobDetails.details.description}
        requirements={jobDetails.details.requirements}
        responsibilities={jobDetails.details.responsibilities}
        preferred={jobDetails.details.preferred}
      />
      <AboutUs title={jobDetails.title} />
    </>
  ) : null;
};

export default OpenPosition;
