import React from "react";
import styles from "./styles.module.css";

const AboutUs = ({ title }) => {
  return (
    <div className={styles.container}>
      <div className={styles.flex}>
        <span className={styles.sectionName}>About Exein</span>
        <a href={`mailto:jobs@exein.io?subject=${title}`} id="apply">
          <button className={styles.applyNowButton}>
            <span>Apply now</span>
            <img src="./icons/add.svg" width={15} alt="add" />
          </button>
        </a>
      </div>
      <div className={styles.content}>
        <div className={styles.grid}>
          <h1>About Us</h1>
          <div className={styles.paragraphs}>
            <div>
              <p>
                We are on a mission to build the world’s first ecosystem for IoT
                device security so that all different types of devices are
                secure around the world.
              </p>
              <p>
                We at Exein are a team of dynamic individuals who love all
                things data, ML, and open source—not to mention passionate about
                firmware! We are constantly working to secure millions of
                devices all over the world to make your daily life safer.
              </p>
            </div>
            <div>
              <p>
                Our unique products enable developers to secure their own IoT
                devices with cutting edge technology in an easy, frictionless
                way. We care deeply about the quality and usefulness of the
                tools we develop, always focusing on our customers and users.
              </p>
              <p>
                We believe open source software is a force for good, and we’re
                building Exein to make it easier for developers who aren’t
                security experts to stay secure.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
