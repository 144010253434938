import React from "react";
import styles from "./styles.module.css";

const Intro = () => (
  <div className={styles.container}>
    <div className={styles.content}>
      <div className={styles.flex}>
        <span>Carrers</span>
        <div>
          <h1>Work with Us</h1>
          <p className={styles.subtitle}>
            Help develop the next generation of IoT security solutions.
          </p>
        </div>
      </div>
      <div className={styles.anchorTags}>
        <div
          onClick={() => document.getElementById("benefits").scrollIntoView()}
        >
          Exein Benefits
        </div>
        <div
          onClick={() =>
            document.getElementById("interviewProcess").scrollIntoView()
          }
        >
          Interview Process
        </div>
        <div
          onClick={() =>
            document.getElementById("openPositions").scrollIntoView()
          }
        >
          Open Positions
        </div>
      </div>
    </div>
  </div>
);

export default Intro;
