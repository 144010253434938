import React from "react";
import { Link } from "react-router-dom";
import positionsData from "../../../data/positions";
import styles from "./styles.module.css";

const OpenPositions = () => (
  <div id="openPositions" className={styles.container}>
    <span className={styles.sectionName}>Open Positions</span>
    <div className={styles.content}>
      <h1>Open Positions</h1>
      <div className={styles.gridContainer}>
        {positionsData.map((elem, i) => (
          <div key={i} className={styles.gridRow}>
            <img
              src="./icons/arrow_right_white.svg"
              width={25}
              color="white"
              alt="arrow"
              className={styles.gridRowIcon}
            />
            <div className={styles.title}>{elem.title}</div>
            <div className={styles.description}>{elem.description}</div>
            <Link to={`/${elem.id}`}>
              <button className={styles.detailsButton}>
                Explore more
                <img
                  src="./icons/chevron_right.svg"
                  width={15}
                  alt="chevron right"
                />
              </button>
            </Link>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default OpenPositions;
