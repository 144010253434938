import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";

const Header = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Link to="/">
          <img src="./exein-logo.png" width={50} alt="logo" />
        </Link>
        <a href="https://www.exein.io/">
          <div className={styles.links}>Company</div>
        </a>
      </div>
    </div>
  );
};

export default Header;
