import React from "react";
import styles from "./styles.module.css";

const Footer = () => {
  return (
    <div className={styles.container}>
      <div className={styles.flex}>
        <img src="./exein-footer-logo.png" alt="logo" width={35} height={35} />
        <div>
          <div className={styles.address}>
            <b>Rome</b> Piazzale Flaminio 19, 00196, Italy <br />
            <b>San Francisco</b> 535 Mission St 14th floor, CA 94105
          </div>
          <div>© Exein SpA · VAT IT14881441001</div>
        </div>
      </div>
      <div className={styles.socialLogos}>
        <a href="https://twitter.com/exein_io">
          <img src="./socialLogos/twitter-logo.svg" alt="" width={20} />
        </a>
        <a href="https://www.linkedin.com/company/exein/">
          <img src="./socialLogos/linkedin-logo.svg" alt="" width={20} />
        </a>
        <a href="https://www.facebook.com/exeinspa/">
          <img src="./socialLogos/facebook-logo.svg" alt="" width={20} />
        </a>
        <a href="https://github.com/Exein-io">
          <img src="./socialLogos/github-logo.svg" alt="" width={20} />
        </a>
      </div>
    </div>
  );
};

export default Footer;
