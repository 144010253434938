import { Route, Switch } from "react-router-dom";
import Layout from "./components/@commons/Layout";
import positionsData from "./data/positions";
import Home from "./pages/Home";
import OpenPosition from "./pages/OpenPosition";

const App = () => (
  <Layout>
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      {positionsData.map((data, i) => (
        <Route key={i} path={`/${data.id}`}>
          <OpenPosition />
        </Route>
      ))}
    </Switch>
  </Layout>
);

export default App;
